import { FlexColumn } from "@/components/Flex";
import { themeColors } from "@/utils/themeColors";
import React from "react";

export type CardSkeletonProps = {
    style: React.CSSProperties;
    renderTopSection: () => React.ReactNode;
    renderMiddleSection: () => React.ReactNode;
    renderBottomSection: () => React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

const CardSkeleton = ({
    ref,
    style,
    renderTopSection,
    renderMiddleSection,
    renderBottomSection,
    ...domProps
}: CardSkeletonProps & {
    ref?: React.RefObject<HTMLDivElement>;
}) => {
    return (
        <FlexColumn
            ref={ref}
            style={{
                position: "relative",
                color: themeColors.neutralBlack,
                justifyContent: "center",
                backgroundColor: themeColors.neutralWhite,
                borderRadius: "2rem",
                padding: "2rem",
                gap: "0",
                ...style,
            }}
            {...domProps}>
            {renderTopSection()}
            {renderMiddleSection()}
            {renderBottomSection()}
        </FlexColumn>
    );
};

CardSkeleton.displayName = "CardSkeleton";
export default CardSkeleton;
