import Rating, { RatingProps } from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";

type StarRatingProps = { value: number } & Omit<RatingProps, "value">;

const BACKEND_MAX_RATING = 5;

/**
 * Wrapper for MUI Rating with opinionated calculation on "rating per number of stars" following `BACKEND_MAX_RATING`
 * to fit our app needs
 * - Adjust stars size by passing `fontSize` to `sx`.
 * - What about the gap between item (averageText - StarsRating - countText)?
     Wrap this component in a flex and define your self how much gap you want.
     ex: `<FlexRowAlignCenter style={{ gap: ... }}><StarRating .../><FlexRowAlignCenter>`
 */
const StarRating = ({ value = 0, max = 5, precision = 0.5, ...starRatingProps }: StarRatingProps) => {
    const averagePerBackendMax = (value / BACKEND_MAX_RATING) * 100;
    const averagePerNumOfStar = (averagePerBackendMax / 100) * max;

    return (
        <Rating
            name="item rating"
            emptyIcon={<StarIcon fontSize="inherit" />}
            precision={precision}
            max={max}
            value={averagePerNumOfStar}
            {...starRatingProps}
        />
    );
};

export default StarRating;
