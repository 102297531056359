import CircularButton, { CircularButtonProps } from "@/components/CircularButton";
import { themeColors } from "@/utils/themeColors";

const UserContentBorderedBtn = ({ children, sx, ...props }: CircularButtonProps) => {
    return (
        <CircularButton
            className="secondaryTextBold1"
            sx={[
                {
                    padding: "0.8rem 0.4rem",
                    fontSize: "1.2rem",
                    width: "2.4rem",
                    height: "2.4rem",
                    border: `1px solid ${themeColors.neutral1}`,
                    backgroundColor: "transparent",
                    "& svg": {
                        flexShrink: 0,
                        width: "1.2rem",
                        height: "1.2rem",
                        strokeWidth: 2.5,
                    },
                    "&:hover": {
                        outline: `1px solid ${themeColors.neutralBlack}`,
                    },
                },
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
            {...props}>
            {children}
        </CircularButton>
    );
};

export default UserContentBorderedBtn;
