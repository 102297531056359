import { Flex, FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import SparkleIcon from "@/components/icons/SparkleIcon";
import { ASSETS_URL } from "@/config/deployConstants";
import useSensitiveScrolling from "@/hooks/styles/useSensitiveScrolling";
import { themeColors } from "@/utils/themeColors";
import { Assignment, AssignmentType, type FlashcardSet, type Media, type Note } from "@knowt/syncing/graphql/schema";
import { useClass, useClasses } from "@knowt/syncing/hooks/classes/useClass";
import { isMedia } from "@knowt/syncing/utils/dataCleaning";
import { timeDeltaFromNow } from "@knowt/syncing/utils/dateTimeUtils";
import { isDarkColor } from "@knowt/syncing/utils/genericUtils";
import Image from "next/image";
import { CarouselDisplay, returnPerCarouselType, returnPerMediaType } from "../utils";
import { ContentPill } from "./UserContentCardBtns";

const TeacherClassPills = ({ item }: { item: Note | FlashcardSet | Media | Assignment }) => {
    const sectionsDivRef = useSensitiveScrolling<HTMLDivElement>(30);

    const { course } = useClass({ classId: item.classId });

    const allSections = !item.sections;
    const noSections = !item.sections?.length;

    return (
        <FlexColumn style={{ width: "100%" }}>
            <div className="secondaryText2" style={{ marginBottom: "0.7rem", fontWeight: 600 }}>
                {"Shared with"}
            </div>
            {allSections || noSections ? (
                <ContentPill
                    className="secondaryText2"
                    style={{
                        border: `1px solid ${allSections ? "transparent" : themeColors.errorPrimary}`,
                        backgroundColor: allSections ? themeColors.background : "transparent",
                        color: allSections ? themeColors.neutralBlack : themeColors.errorPrimary,
                    }}>
                    {allSections ? "All sections" : "No sections"}
                </ContentPill>
            ) : (
                <Flex
                    ref={sectionsDivRef}
                    className="scrollbar"
                    style={{ gap: "0.7rem", flexWrap: "wrap", maxHeight: "7.8rem", overflowY: "scroll" }}>
                    {item.sections?.map(sectionId => (
                        <ContentPill
                            key={sectionId}
                            className="ellipsisText"
                            style={{
                                width: "9rem",
                                display: "inline",
                                textAlign: "center",
                            }}>
                            {course?.sections.find(({ id }) => id === sectionId)?.name}
                        </ContentPill>
                    ))}
                </Flex>
            )}
        </FlexColumn>
    );
};

const StudentClassPills = ({ item }: { item: Note | FlashcardSet | Media | Assignment }) => {
    const getPostedTimeDelta = () => {
        const time = timeDeltaFromNow(item?.addedAt ? +item?.addedAt * 1000 : 0);
        return `Posted ${time} ago`;
    };

    return <ContentPill className="ellipsisText">{getPostedTimeDelta()}</ContentPill>;
};

const ClassPill = ({ item }: { item: Note | FlashcardSet | Media | Assignment }) => {
    const { classes } = useClasses();
    const course = classes?.[item.classId ?? ""];

    if (!course) {
        return null;
    }

    return (
        <ContentPill
            className="ellipsisText"
            style={{
                backgroundColor: course.color,
                color: course.color
                    ? isDarkColor(course.color)
                        ? themeColors.pureWhite
                        : themeColors.pureBlack
                    : themeColors.neutralWhite,
            }}>
            {course.name}
        </ContentPill>
    );
};

const UserContentCardPills = ({
    type,
    item,
    isTeacherClass = false,
    isStudentClass = false,
    props,
    relatedMedia,
}: {
    type: CarouselDisplay;
    item: Note | FlashcardSet | Media | Assignment;
    isTeacherClass?: boolean;
    isStudentClass?: boolean;
    relatedMedia?: Media;
    props?: { numOfTerms: number | null };
}) => {
    const getContentType = type => {
        const icon = returnPerCarouselType(type, {
            note: (
                <Image
                    src={`${ASSETS_URL}/images/note-icon${item.isAI ? "-ai" : ""}.svg`}
                    alt="note"
                    width="15"
                    height="15"
                />
            ),
            flashcardSet: (
                <Image
                    src={`${ASSETS_URL}/images/flashcards-icon${item.isAI ? "-ai" : ""}.svg`}
                    alt="flashcards"
                    width="15"
                    height="15"
                />
            ),
            assignment: (
                <Image src={`${ASSETS_URL}/images/assignment-icon.svg`} alt="assignment" width="15" height="15" />
            ),
            video: <Image src={`${ASSETS_URL}/images/video-icon.svg`} alt="video" width="15" height="15" />,
            audio: <Image src={`${ASSETS_URL}/images/audio-icon.svg`} alt="audio" width="15" height="15" />,
            pdf: <Image src={`${ASSETS_URL}/images/pdf-icon.svg`} alt="PDF" width="15" height="15" />,
            excel: <Image src={`${ASSETS_URL}/images/xlsx-icon.svg`} alt="xlsx" width="15" height="15" />,
            ppt: <Image src={`${ASSETS_URL}/images/pptx-icon.svg`} alt="pptx" width="15" height="15" />,
        });

        const typeName = returnPerCarouselType(type, {
            note: "Note",
            flashcardSet: "Flashcards",
            assignment: (item as Assignment).type === AssignmentType.CHAT ? "Chat Activity" : "Assignment",
            video: "Video",
            pdf: "PDF",
            excel: "Excel",
            audio: "Audio",
            ppt: "PowerPoint",
        });

        return (
            <FlexRowAlignCenter as="span" style={{ gap: "0.5rem" }}>
                {icon} {typeName}{" "}
                {type === CarouselDisplay.FLASHCARDSET && !!props?.numOfTerms ? `(${props?.numOfTerms})` : null}
            </FlexRowAlignCenter>
        );
    };

    return (
        <FlexColumn style={{ flexWrap: "wrap", gap: "0.8rem", overflow: "hidden", marginBottom: "0.6rem", flex: 1 }}>
            <FlexRowAlignCenter style={{ gap: "0.8rem", flexWrap: "wrap" }}>
                {(item.isAI || type === CarouselDisplay.ASSIGNMENT) && (
                    <ContentPill style={{ background: themeColors.aiGradient }}>
                        <FlexRowAlignCenter style={{ gap: "0.5rem", color: themeColors.pureBlack }}>
                            <SparkleIcon size={15} />
                            AI
                        </FlexRowAlignCenter>
                    </ContentPill>
                )}

                <ContentPill
                    style={{
                        backgroundColor: returnPerCarouselType(type, {
                            note: "var(--color-note-pill)",
                            flashcardSet: "var(--color-flashcardSet-pill)",
                            assignment: "var(--color-assignment-pill)",
                            video: "var(--color-video-pill)",
                            pdf: "var(--color-pdf-pill)",
                            excel: "var(--color-excel-pill)",
                            ppt: "var(--color-ppt-pill)",
                            audio: "var(--color-audio-pill)",
                        }),
                    }}>
                    {getContentType(type)}
                </ContentPill>

                {!isMedia(item) && item?.mediaId && relatedMedia && (
                    <ContentPill
                        style={{
                            backgroundColor: returnPerCarouselType(
                                returnPerMediaType(relatedMedia, {
                                    pdf: CarouselDisplay.PDF,
                                    video: CarouselDisplay.VIDEO,
                                    excel: CarouselDisplay.Excel,
                                    ppt: CarouselDisplay.PPT,
                                    audio: CarouselDisplay.AUDIO,
                                }),
                                {
                                    note: "var(--color-note-pill)",
                                    flashcardSet: "var(--color-flashcardSet-pill)",
                                    assignment: "var(--color-assignment-pill)",
                                    video: "var(--color-video-pill)",
                                    pdf: "var(--color-pdf-pill)",
                                    excel: "var(--color-excel-pill)",
                                    ppt: "var(--color-ppt-pill)",
                                    audio: "var(--color-audio-pill)",
                                }
                            ),
                        }}>
                        {getContentType(
                            returnPerMediaType(relatedMedia, {
                                pdf: CarouselDisplay.PDF,
                                video: CarouselDisplay.VIDEO,
                                excel: CarouselDisplay.Excel,
                                ppt: CarouselDisplay.PPT,
                                audio: CarouselDisplay.AUDIO,
                            })
                        )}
                    </ContentPill>
                )}
                {isTeacherClass ? (
                    <TeacherClassPills item={item} />
                ) : isStudentClass ? (
                    <StudentClassPills item={item} />
                ) : item.classId ? (
                    <ClassPill item={item} />
                ) : null}
            </FlexRowAlignCenter>
        </FlexColumn>
    );
};

export default UserContentCardPills;
