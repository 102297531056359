import { Assignment, FlashcardSet, ItemType, Media, Note } from "@knowt/syncing/graphql/schema";
import { getDraftNoteUrl, getNoteUrl } from "@/utils/url";
import { isPDF, isExcel, isPPT, isVideo, isAudio } from "@knowt/syncing/hooks/media/checkers";
import { isAssignment, isFlashcardSet, isMedia, isNote } from "@knowt/syncing/utils/dataCleaning";
import { ClassDashboardTab } from "@/features/UserContentManagement/ClassPage/types";

export enum CarouselDisplay {
    NOTE = "NOTE",
    FLASHCARDSET = "FLASHCARDSET",
    ASSIGNMENT = "ASSIGNMENT",
    VIDEO = "Video",
    PDF = "PDF",
    AUDIO = "Audio",
    Excel = "Excel",
    PPT = "PPT",
}

export const getCarouselDisplayFromItemType = (itemType: ItemType) => {
    switch (itemType) {
        case ItemType.NOTE:
            return CarouselDisplay.NOTE;
        case ItemType.FLASHCARDSET:
            return CarouselDisplay.FLASHCARDSET;
        case ItemType.ASSIGNMENT:
            return CarouselDisplay.ASSIGNMENT;
        case ItemType.MEDIA:
            return CarouselDisplay.VIDEO;
        default:
            throw new Error(
                "`getCarouselDisplayFromItemType` should be call with note or flashcardSet or media, got: " + itemType
            );
    }
};

export const getItemUrl = ({
    note,
    flashcardSet,
    media,
    assignment,
    isTeacher,
}: {
    note?: Note;
    flashcardSet?: FlashcardSet;
    media?: Media;
    assignment?: Assignment;
    isTeacher?: boolean;
}): string => {
    if (note) {
        const { noteId, title, draft } = note;
        return isTeacher
            ? `/class/${note.classId}/dashboard?tab=${ClassDashboardTab.MATERIALS}&itemId=${noteId}&itemType=${ItemType.NOTE}`
            : draft
              ? getDraftNoteUrl(note)
              : getNoteUrl({ noteId, ...(title ? { title } : {}) });
    } else if (flashcardSet) {
        const { draft, flashcardSetId } = flashcardSet;
        return draft
            ? `/flashcards/${flashcardSetId}/edit`
            : isTeacher
              ? `/class/${flashcardSet.classId}/dashboard?tab=${ClassDashboardTab.MATERIALS}&itemId=${flashcardSetId}&itemType=${ItemType.FLASHCARDSET}`
              : `/flashcards/${flashcardSetId}`;
    } else if (media) {
        return `/ai-media-note/${media?.mediaId}`;
    } else if (assignment) {
        const { assignmentId } = assignment;
        return isTeacher
            ? `/class/${assignment.classId}/dashboard?tab=${ClassDashboardTab.MATERIALS}&itemId=${assignmentId}&itemType=${ItemType.ASSIGNMENT}`
            : `/assignment/${assignmentId}`;
    }

    throw new Error("`getItemUrl` should be call with note or flashcardSet or media");
};

export const returnPerCarouselType = <T,>(
    type: CarouselDisplay,
    returns: { note: T; flashcardSet: T; assignment: T; video: T; pdf: T; excel: T; ppt: T; audio: T }
) => {
    switch (type) {
        case CarouselDisplay.NOTE:
            return returns.note;
        case CarouselDisplay.FLASHCARDSET:
            return returns.flashcardSet;
        case CarouselDisplay.ASSIGNMENT:
            return returns.assignment;
        case CarouselDisplay.VIDEO:
            return returns.video;
        case CarouselDisplay.PDF:
            return returns.pdf;
        case CarouselDisplay.Excel:
            return returns.excel;
        case CarouselDisplay.PPT:
            return returns.ppt;
        case CarouselDisplay.AUDIO:
            return returns.audio;
        default:
            throw new Error("`returnPerCarouselType` should be call with note or flashcardSet or media, got: " + type);
    }
};

export const returnPerType = <T,>(
    item: Note | FlashcardSet | Media | Assignment,
    returns: { note: T; flashcardSet: T; media: T; assignment: T }
) => {
    if (isNote(item)) {
        return returns.note;
    }
    if (isFlashcardSet(item)) {
        return returns.flashcardSet;
    }
    if (isMedia(item)) {
        return returns.media;
    }
    if (isAssignment(item)) {
        return returns.assignment;
    }

    // biome-ignore lint/suspicious/noConsole:
    console.log("ITEM", item);
    throw new Error("`returnPerType` should be call with note or flashcardSet or media,  ");
};

export const returnPerNoteType = <T,>(item: Note, returns: { note: T; pdf: T }) => {
    if (item.file && !item.content) {
        return returns.pdf;
    }
    return returns.note;
};

export const returnPerMediaType = <T,>(item: Media, returns: { pdf: T; excel: T; ppt: T; video: T; audio: T }) => {
    if (isPDF(item)) return returns.pdf;
    if (isExcel(item)) return returns.excel;
    if (isPPT(item)) return returns.ppt;
    if (isAudio(item)) return returns.audio;
    if (isVideo(item)) return returns.video;

    return returns.video;
    // throw new Error("`returnPerMediaType` should be call with note or flashcardSet or media");
};
