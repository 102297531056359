import { createQueryStrings } from "@knowt/syncing/utils/genericUtils";
import { isEqual } from "lodash-es";
import { usePathname } from "next/navigation";
import { useRouter } from "@bprogress/next";
import { useCallback } from "react";

type ParamsInput = { [key in string]: string | number | boolean | null | undefined };

export const useSafeQueryNavigation = () => {
    const pathname = usePathname();
    const router = useRouter();

    const reroute = useCallback(
        (type: "push" | "replace" | "silentPush" | "silentReplace", action: "add" | "remove" | "replace") =>
            (params: ParamsInput) => {
                const currentParams = Object.fromEntries(new URLSearchParams(window.location.search));

                let queryParams: ParamsInput = { ...currentParams };
                if (action === "replace") {
                    // if we are replacing, we need to clear the current params
                    queryParams = {};
                }

                for (const [key, value] of Object.entries(params)) {
                    if (action !== "remove" && value) {
                        queryParams[key] = value;
                    } else {
                        delete queryParams[key];
                    }
                }

                if (isEqual(queryParams, currentParams)) {
                    return;
                }

                const query = createQueryStrings(queryParams);

                if (type === "push") {
                    router.push(pathname + query);
                } else if (type === "silentPush") {
                    window.history.pushState({}, "", pathname + query);
                } else if (type === "silentReplace") {
                    window.history.replaceState({}, "", pathname + query);
                } else {
                    window.location.replace(pathname + query);
                }
            },
        [pathname, router]
    );

    return {
        addParamsPush: (params: ParamsInput) => reroute("push", "add")(params),
        addParamsSilentPush: (params: ParamsInput) => reroute("silentPush", "add")(params),
        addParamsReplace: (params: ParamsInput) => reroute("replace", "add")(params),
        addParamsSilentReplace: (params: ParamsInput) => reroute("silentReplace", "add")(params),
        removeParamsPush: (params: string[]) =>
            // biome-ignore lint: performance/noAccumulatingSpread
            reroute("push", "remove")(params.reduce((acc, curr) => ({ ...acc, [curr]: null }), {})),
        removeParamsReplace: (params: string[]) =>
            // biome-ignore lint: performance/noAccumulatingSpread
            reroute("replace", "remove")(params.reduce((acc, curr) => ({ ...acc, [curr]: null }), {})),
        setParamsPush: (params: ParamsInput) => reroute("push", "replace")(params),
        setParamsReplace: (params: ParamsInput) => reroute("push", "replace")(params),
    };
};
