// https://usehooks.com/useEventListener/
import { useEffect, useRef } from "react";

type EventHandler<K extends keyof WindowEventMap> = (e: WindowEventMap[K]) => void;

const useDOMEventListener = <K extends keyof WindowEventMap>({
    on,
    handler,
    el = typeof window !== "undefined" ? window : undefined,
    options,
}: {
    on: K;
    handler: EventHandler<K>;
    el?: Document | Element | Window | undefined | null;
    options?: boolean | AddEventListenerOptions;
}) => {
    // Create a ref that stores handler
    const savedHandler = useRef<EventHandler<K>>();

    // Update ref.current value if handler changes.
    // This allows our effect below to always get latest handler ...
    // ... without us needing to pass it in effect deps array ...
    // ... and potentially cause effect to re-run every render.
    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    useEffect(
        () => {
            // Make sure element supports addEventListener
            const isSupported = el && el.addEventListener;
            if (!isSupported) return;

            // Create event listener that calls handler function stored in ref
            const eventListener: EventListener = event => {
                if (savedHandler.current) {
                    savedHandler.current(event as WindowEventMap[K]);
                }
            };

            // Add event listener
            el.addEventListener(on, eventListener, options);

            // Remove event listener on cleanup
            return () => {
                el.removeEventListener(on, eventListener, options);
            };
        },
        [on, el, options] // Re-run if eventName, element, or options changes
    );
};

export default useDOMEventListener;
