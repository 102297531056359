export enum ClassDashboardTab {
    MAIN = "main",
    PEOPLE = "people",
    MATERIALS = "materials",
    SETTINGS = "settings",
}

export enum ClassFilesTab {
    HOME = "dashboard",
    SAVED = "people",
}

export enum CLASS_SETTINGS_TABS {
    GENERAL = "GENERAL",
    APPEARANCE = "APPEARANCE",
    SECTIONS = "SECTIONS",
    STUDY_MODE_RESTRICTIONS = "STUDY_MODE_RESTRICTIONS",
}
