"use client";
import { Flex, FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import LinkWrapper from "@/components/wrappers/Link/Link";
import { useCardPreviewContextSelector } from "@/contexts/CardPreviewContext";
import { getDraftNoteUrl, getNoteUrl } from "@/utils/url";
import {
    Assignment,
    AssignmentStatus,
    ClassPermission,
    type FlashcardSet,
    ItemType,
    type Media,
    type Note,
    UserDetails,
} from "@knowt/syncing/graphql/schema";
import { useClass } from "@knowt/syncing/hooks/classes/useClass";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import {
    ExclusiveFlashcardSetItem,
    ExclusiveMediaItem,
    ExclusiveNoteItem,
    ExclusiveAssignmentItem,
} from "@knowt/syncing/types/common";
import { UNTITLED, isAssignment, isFlashcardSet, isMedia, isNote } from "@knowt/syncing/utils/dataCleaning";
import clsx from "clsx";
import { isEqual } from "lodash-es";
import dynamic from "next/dynamic";
import { useRouter } from "@bprogress/next";
import { CSSProperties, memo, useState } from "react";
import toast from "react-hot-toast";
import CardSkeleton from "../CardSkeleton";
import {
    BookmarkBtnWithHandler,
    CarouselArrowBtn,
    CarouselDots,
    clickOptionMenuBtn,
    PreviewButtonWithHandler,
    TitleWrapper,
} from "./components/UserContentCardBtns";
import UserContentCardPills from "./components/UserContentCardPills";
import styles from "./userContentCard.module.css";
import { CarouselDisplay, returnPerCarouselType, returnPerMediaType, returnPerNoteType, returnPerType } from "./utils";
import { ClassDashboardTab } from "@/features/UserContentManagement/ClassPage/types";
import { useSafeQueryNavigation } from "@/hooks/navigation/useSafeQueryNavigation";
import Mountable from "@/components/styled/div/Mountable";
import { timeDeltaFromNow } from "@knowt/syncing/utils/dateTimeUtils";
import { useParams } from "next/navigation";

const PublicIcon = dynamic(() => import("./components/UserContentCardBtns").then(m => m.PublicIcon));
const OwnerDetails = dynamic(() => import("./components/UserContentCardBtns").then(m => m.OwnerDetails));
const SelectableButton = dynamic(() => import("./components/UserContentCardBtns").then(m => m.SelectableButton));
const NoteOptionsMenu = dynamic(() => import("./components/NoteOptionsMenu"), { ssr: false });
const FlashcardSetOptionsMenu = dynamic(() => import("./components/FlashcardSetOptionsMenu"), { ssr: false });
const MediaOptionsMenu = dynamic(() => import("./components/MediaOptionsMenu"), { ssr: false });
const AssignmentOptionsMenu = dynamic(() => import("./components/AssignmentOptionsMenu"), { ssr: false });

type ModeProps = {
    mode: "SELECT" | null;
    isSelected: boolean;
    toggleSelect: (itemId: string, item: Note | FlashcardSet | Media | Assignment) => void;
};

type UserContentCardProps = {
    modeProps?: ModeProps;
    ownerDetails?: UserDetails;
    style?: CSSProperties;
    classProps?: {
        isTeacherClass?: boolean;
        isStudentClass?: boolean;
        forcePinned?: boolean;
        readOnly?: boolean;
    };
} & (ExclusiveNoteItem | ExclusiveFlashcardSetItem | ExclusiveMediaItem | ExclusiveAssignmentItem);

const getCurrentDisplayCarousel = (
    item: Note | FlashcardSet | Media | Assignment,
    carouselIndex: number
): CarouselDisplay => {
    if (carouselIndex === 0) {
        return returnPerType(item, {
            note: returnPerNoteType(item as Note, {
                note: CarouselDisplay.NOTE,
                pdf: CarouselDisplay.PDF,
            }),
            flashcardSet: CarouselDisplay.FLASHCARDSET,
            media: returnPerMediaType(item as Media, {
                pdf: CarouselDisplay.PDF,
                video: CarouselDisplay.VIDEO,
                excel: CarouselDisplay.Excel,
                ppt: CarouselDisplay.PPT,
                audio: CarouselDisplay.AUDIO,
            }),
            assignment: CarouselDisplay.ASSIGNMENT,
        }) as CarouselDisplay;
    }

    if (carouselIndex === 1) {
        if (isNote(item) || isMedia(item)) {
            const c1Display = returnPerType<CarouselDisplay | undefined>(item, {
                note: item.flashcardSetId
                    ? CarouselDisplay.FLASHCARDSET
                    : (item as Note).file
                      ? CarouselDisplay.PDF
                      : undefined,
                flashcardSet: undefined,
                media: item.noteId ? CarouselDisplay.NOTE : CarouselDisplay.FLASHCARDSET,
                assignment: undefined,
            });

            if (c1Display) return c1Display;
        }
    }

    if (carouselIndex === 2) {
        if (isNote(item) || isMedia(item)) {
            const c2Display = returnPerType<CarouselDisplay | undefined>(item, {
                note: !(item as Note).content && (item as Note).file ? CarouselDisplay.PDF : undefined,
                flashcardSet: undefined,
                media: item.flashcardSetId ? CarouselDisplay.FLASHCARDSET : undefined,
                assignment: undefined,
            });

            if (c2Display) return c2Display;
        }
    }

    throw new Error("Nothing returned from `getCurrentDisplayCarousel`");
};

const getMaxNumCarousel = (item: Note | FlashcardSet | Media | Assignment) => {
    let max = 1;

    if (isNote(item)) {
        if (item.flashcardSetId) {
            max++;
        }
        if (item.content && item.file) {
            max++;
        }
    } else if (isFlashcardSet(item)) {
        // nothing so far, probably will have condition in future
    } else if (isMedia(item)) {
        if (item.noteId) {
            max++;
        }
        if (item.flashcardSetId) {
            max++;
        }
    } else if (isAssignment(item)) {
        // nothing so far, probably will have condition in future
    }

    return max;
};

const UserContentCard = ({
    note,
    flashcardSet,
    media,
    assignment,
    modeProps,
    classProps,
    style,
    ownerDetails,
}: UserContentCardProps) => {
    const params = useParams();
    const { userId } = useCurrentUser();
    const router = useRouter();

    const typeIsNote = !!note;
    const typeIsFlashcardSet = !!flashcardSet;

    const item: Note | FlashcardSet | Media | Assignment = note || flashcardSet || media || assignment;

    const readOnly = item.userId !== userId;
    const { permission } = useClass({ classId: item.classId, isEnabled: !!item.classId });

    const allowViewingMaterialStats = permission !== ClassPermission.NONE;
    const isOnSameClassPage = params.classId === item.classId;

    const isReadOnlyClassItem = item.classId && permission !== ClassPermission.ALL && item.userId !== userId;

    const itemId = returnPerType(item, {
        note: (item as Note).noteId,
        flashcardSet: (item as FlashcardSet).flashcardSetId,
        media: (item as Media).mediaId,
        assignment: (item as Assignment).assignmentId,
    });

    const titlePrefix = returnPerType(item, {
        note: (item as Note).draft ? "(Draft) " : "",
        flashcardSet: (item as FlashcardSet).draft ? "(Draft) " : "",
        media: !(item as Media).noteId && !(item as Media).flashcardSetId ? "(Draft) " : "",
        assignment: (item as Assignment).status === AssignmentStatus.DRAFT ? "(Draft) " : "",
    });

    const { addParamsSilentPush } = useSafeQueryNavigation();

    const [carouselIdx, setCarouselIdx] = useState(0);

    const maxCarouselNum = getMaxNumCarousel(item);
    const showCarousel = maxCarouselNum > 1;
    const currDisplayCarousel = getCurrentDisplayCarousel(item, carouselIdx);

    const isCurrentlyInView = useCardPreviewContextSelector(state => state.isCurrentlyInView);
    const isPreviewOpen = useCardPreviewContextSelector(state => state.isOpen);

    const inView =
        isCurrentlyInView({
            noteId: (item as Note).noteId,
            flashcardSetId: (item as FlashcardSet).flashcardSetId,
        }) && isPreviewOpen;

    const isTrash = item.trash;

    const incrementCarousel = (by: number) => {
        setCarouselIdx(prev => Math.abs((prev + by) % maxCarouselNum));
    };

    const href = returnPerCarouselType(currDisplayCarousel, {
        note: (item as Note).draft
            ? getDraftNoteUrl(item as Note)
            : allowViewingMaterialStats
              ? `/class/${item.classId}/dashboard?tab=${ClassDashboardTab.MATERIALS}&itemId=${(item as Note).noteId}&itemType=${ItemType.NOTE}`
              : getNoteUrl({
                    noteId: (item as Note).noteId,
                    title: (item as Note).title || UNTITLED,
                }),
        flashcardSet: (item as FlashcardSet).draft
            ? `/flashcards/${(item as FlashcardSet).flashcardSetId}/edit`
            : allowViewingMaterialStats
              ? `/class/${item.classId}/dashboard?tab=${ClassDashboardTab.MATERIALS}&itemId=${(item as FlashcardSet).flashcardSetId}&itemType=${ItemType.FLASHCARDSET}`
              : `/flashcards/${(item as FlashcardSet).flashcardSetId}`,
        assignment:
            (item as Assignment).status === AssignmentStatus.DRAFT
                ? `/assignment/${(item as Assignment).assignmentId}/settings`
                : allowViewingMaterialStats
                  ? `/class/${item.classId}/dashboard?tab=${ClassDashboardTab.MATERIALS}&itemId=${(item as Assignment).assignmentId}&itemType=${ItemType.ASSIGNMENT}`
                  : `/assignment/${(item as Assignment).assignmentId}`,
        video: `/ai-media-note/${(item as Media).mediaId}`,
        pdf: `/ai-media-note/${(item as Media).mediaId}`,
        excel: `/ai-media-note/${(item as Media).mediaId}`,
        audio: `/ai-media-note/${(item as Media).mediaId}`,
        ppt: `/ai-media-note/${(item as Media).mediaId}`,
    });

    // actions are to silently push to the stats page as its faster since half
    // the page is already loaded and we just need to lazy load the rest, only if we
    // are on the same class page, not if on home or materials page
    const action = returnPerCarouselType(currDisplayCarousel, {
        note:
            (item as Note).draft && (item as Note).isAI
                ? undefined
                : allowViewingMaterialStats && isOnSameClassPage
                  ? () =>
                        addParamsSilentPush({
                            tab: ClassDashboardTab.MATERIALS,
                            itemId: (item as Note).noteId,
                            itemType: ItemType.NOTE,
                        })
                  : undefined,
        flashcardSet: (item as FlashcardSet).draft
            ? undefined
            : allowViewingMaterialStats && isOnSameClassPage
              ? () =>
                    addParamsSilentPush({
                        tab: ClassDashboardTab.MATERIALS,
                        itemId: (item as FlashcardSet).flashcardSetId,
                        itemType: ItemType.FLASHCARDSET,
                    })
              : undefined,
        video: undefined,
        pdf: undefined,
        assignment:
            (item as Assignment).status === AssignmentStatus.DRAFT
                ? undefined
                : allowViewingMaterialStats && isOnSameClassPage
                  ? () =>
                        addParamsSilentPush({
                            tab: ClassDashboardTab.MATERIALS,
                            itemId: (item as Assignment).assignmentId,
                            itemType: ItemType.ASSIGNMENT,
                        })
                  : undefined,
        excel: undefined,
        audio: undefined,
        ppt: undefined,
    });

    const handleSelectItem = () => {
        if (isReadOnlyClassItem) return toast.error("You do not have permission to control this item");

        modeProps?.toggleSelect(itemId, item);
    };

    const renderTopSection = () => {
        return (
            <Flex
                as={modeProps?.mode === "SELECT" || isTrash ? "div" : LinkWrapper}
                onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    if (modeProps?.mode === "SELECT") {
                        handleSelectItem();
                    }
                }}
                className="strippedLink"
                style={{ color: "inherit", height: "6rem", marginBottom: "1rem" }}
                href={modeProps?.mode === "SELECT" ? undefined : href}>
                <Mountable mount={modeProps?.mode === "SELECT"}>
                    <SelectableButton isSelected={!!modeProps?.isSelected} onClick={() => handleSelectItem()} />
                </Mountable>
                <FlexColumn>
                    <TitleWrapper style={{ marginBottom: "0.4rem" }} title={titlePrefix + (item.title || UNTITLED)} />
                    <Mountable mount={!!item?.updated}>
                        <span className="secondaryText2">
                            Updated {timeDeltaFromNow(item.updated ? +item.updated * 1000 : 0, true)} ago
                        </span>
                    </Mountable>
                </FlexColumn>
            </Flex>
        );
    };

    const renderMiddleSection = () => {
        return (
            <Flex style={{ gap: "1.7rem", flex: 1 }}>
                {showCarousel && <CarouselArrowBtn isNext={false} onClick={() => incrementCarousel(-1)} />}

                <UserContentCardPills
                    item={item}
                    props={{ numOfTerms: flashcardSet?.flashcards?.length || flashcardSet?.size || 0 }}
                    type={currDisplayCarousel}
                    isTeacherClass={classProps?.isTeacherClass}
                    isStudentClass={classProps?.isStudentClass}
                    relatedMedia={media}
                />

                {showCarousel && <CarouselArrowBtn isNext onClick={() => incrementCarousel(1)} />}
            </Flex>
        );
    };

    const renderBottomSection = () => {
        const renderPersonalPills = () => (
            <>
                {ownerDetails ? (
                    <OwnerDetails ownerDetails={ownerDetails} />
                ) : readOnly ? null : (
                    <PublicIcon note={note} flashcardSet={flashcardSet} assignment={assignment} />
                )}
            </>
        );

        const renderClassPills = () => <>{ownerDetails && <OwnerDetails ownerDetails={ownerDetails} />}</>;

        return (
            <FlexRowAlignCenter style={{ gap: "0.8rem" }}>
                {classProps?.isTeacherClass || classProps?.isStudentClass ? renderClassPills() : renderPersonalPills()}
                <div style={{ marginLeft: "auto" }} />

                {(typeIsNote || typeIsFlashcardSet) && (
                    <PreviewButtonWithHandler
                        {...(() => {
                            if (note && currDisplayCarousel === CarouselDisplay.NOTE) {
                                return { noteId: note.noteId };
                            } else if (flashcardSet && currDisplayCarousel === CarouselDisplay.FLASHCARDSET) {
                                return { flashcardSetId: flashcardSet.flashcardSetId };
                            }
                        })()}
                    />
                )}
                <BookmarkBtnWithHandler
                    item={item}
                    itemId={itemId}
                    type={returnPerType(item, {
                        note: ItemType.NOTE,
                        flashcardSet: ItemType.FLASHCARDSET,
                        media: ItemType.MEDIA,
                        assignment: ItemType.ASSIGNMENT,
                    })}
                    forcePinned={classProps?.forcePinned}
                    readOnly={classProps?.readOnly}
                />
                {returnPerType(item, {
                    note: <NoteOptionsMenu note={item as Note} />,
                    flashcardSet: <FlashcardSetOptionsMenu flashcardSet={item as FlashcardSet} />,
                    media: <MediaOptionsMenu media={item as Media} />,
                    assignment: <AssignmentOptionsMenu assignment={item as Assignment} />,
                })}
            </FlexRowAlignCenter>
        );
    };

    const maybeCarouselDots = () => {
        if (!showCarousel) {
            return null;
        }

        return <CarouselDots length={maxCarouselNum} currIdx={carouselIdx} onClick={i => setCarouselIdx(i)} />;
    };

    return (
        <CardSkeleton
            role="link"
            data-testid={"notebook-card"}
            data-href={href}
            tabIndex={0}
            onContextMenu={e => {
                e.preventDefault();
                clickOptionMenuBtn({ id: itemId });
            }}
            className={clsx({
                [styles.userContentCard]: true,
                [styles.carouselColorsInit]: true,
                [styles.assignment]: currDisplayCarousel === CarouselDisplay.ASSIGNMENT,
                [styles.note]: currDisplayCarousel === CarouselDisplay.NOTE,
                [styles.flashcardSet]: currDisplayCarousel === CarouselDisplay.FLASHCARDSET,
                [styles.pdf]: currDisplayCarousel === CarouselDisplay.PDF,
                [styles.video]: currDisplayCarousel === CarouselDisplay.VIDEO,
                [styles.ppt]: currDisplayCarousel === CarouselDisplay.PPT,
                [styles.excel]: currDisplayCarousel === CarouselDisplay.Excel,
                [styles.inView]: inView,
            })}
            onClick={
                modeProps?.mode === "SELECT"
                    ? () => handleSelectItem()
                    : isTrash
                      ? () => {
                            if (isTrash) {
                                toast.error("Please recover this file to open it");
                                return;
                            }
                        }
                      : e => {
                            if (e.metaKey || e.ctrlKey) {
                                return window.open(href, "_blank");
                            }

                            if (action) return action();

                            router.push(href);
                        }
            }
            renderTopSection={renderTopSection}
            renderMiddleSection={renderMiddleSection}
            renderBottomSection={() => {
                return (
                    <>
                        {renderBottomSection()} {maybeCarouselDots()}
                    </>
                );
            }}
            style={{
                width: "100%",
                cursor: "pointer",
                justifyContent: "space-between",
                position: "relative",
                backgroundColor: undefined,
                height: "26.4rem",
                ...style,
            }}
        />
    );
};

export default memo(UserContentCard, (prevProps, nextProps) => isEqual(prevProps, nextProps));
