import { CircularButtonProps } from "@/components/CircularButton";
import ConditionalTooltip from "@/components/ConditionalTooltip";
import { FlexColumnAlignJustifyCenter, FlexRowAlignCenter } from "@/components/Flex";
import StarRating from "@/components/StarRating";
import UserAvatar from "@/components/userCardComponents/UserAvatar";
import { useCardPreviewContextSelector } from "@/contexts/CardPreviewContext";
import { AuthEvent } from "@/features/Auth/AuthPopup/utils/constants";
import { useContentItemsSharingPopupContextSelector } from "@/features/ShareSettings/hooks/useContentItemsSharingContext";
import { useCheckAuth } from "@/hooks/useCheckAuth";
import { preventStopPropogation } from "@/utils/domUtils";
import { iconSizes, strokeWidth } from "@/utils/iconProps";
import { themeColors } from "@/utils/themeColors";
import { Assignment, FlashcardSet, ItemType, Media, Note, UserDetails } from "@knowt/syncing/graphql/schema";
import { useBookmark } from "@knowt/syncing/hooks/bookmarks/useBookmarks";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { UNTITLED } from "@knowt/syncing/utils/dataCleaning";
import {
    Bookmark,
    Check,
    Circle,
    Lock,
    Pin,
    EllipsisVertical,
    ChevronLeft,
    ChevronRight,
    Star,
    Globe,
    KeyRound,
} from "lucide-react";
import React from "react";
import UserContentBorderedBtn from "./styled/UserContentBorderedBtn";
import CircularOutlineIcon from "@/components/CircularButton/styled/CircularOutlineIcon";

export const TitleWrapper = ({ title: rawTitle, style }: { title: string; style?: React.CSSProperties }) => {
    const title = rawTitle.trim() || UNTITLED;
    return (
        <div title={title} className="secondaryTextBold1 clampText" style={style}>
            {title}
        </div>
    );
};

export const PreviewButtonWithHandler = ({ noteId, flashcardSetId }: { noteId?: string; flashcardSetId?: string }) => {
    const openCardPreview = useCardPreviewContextSelector(state => state.openCardPreview);
    const isCurrentlyInView = useCardPreviewContextSelector(state => state.isCurrentlyInView);

    const inView = isCurrentlyInView({ noteId, flashcardSetId });

    return (
        <UserContentBorderedBtn
            onClick={e => {
                preventStopPropogation(e);
                if (!noteId && !flashcardSetId) {
                    return alert("TODO: coming for this, currently only for preview note and flashcards.");
                }

                if (noteId) {
                    return openCardPreview({ noteId });
                } else if (flashcardSetId) {
                    return openCardPreview({ flashcardSetId });
                }

                throw new Error(
                    "failed to call `openCardPreview`, make sure it's only for `noteId` or `flashcardSetId`"
                );
            }}
            sx={{
                backgroundColor: inView ? "#000000" : undefined,
                color: inView ? themeColors.pureWhite : undefined,
                width: "6.5rem",
                borderRadius: "1rem",
            }}>
            Preview
        </UserContentBorderedBtn>
    );
};

export const BookmarkBtnWithHandler = ({
    item,
    itemId,
    type,
    readOnly = false,
    forcePinned = false,
}: {
    item: Note | FlashcardSet | Media | Assignment;
    itemId: string;
    type: ItemType;
    readOnly?: boolean;
    forcePinned?: boolean;
}) => {
    const { bookmarked, toggleBookmarked } = useBookmark({
        itemId: itemId,
        type: type,
        classId: item.classId,
        itemUserId: item.userId,
    });

    const { userId, isStudent } = useCurrentUser();
    const { checkAuth } = useCheckAuth();

    const isPin = forcePinned || item.userId === userId;
    const isSaved = bookmarked || forcePinned;
    const isFavorite = isStudent && item.classId;

    const SaveIcon = isFavorite ? Star : isPin ? Pin : Bookmark;

    return (
        <UserContentBorderedBtn
            onClick={e => {
                preventStopPropogation(e);
                if (!readOnly) {
                    checkAuth({
                        event: AuthEvent.BOOKMARK_ITEM,
                        onAuthSuccess: () => toggleBookmarked(),
                    });
                }
            }}
            tooltip={
                readOnly
                    ? "Class pinned item"
                    : bookmarked
                      ? isFavorite
                          ? "Remove favorite"
                          : isPin
                            ? "Unpin this"
                            : "Remove from saved"
                      : isFavorite
                        ? "Add to favorites"
                        : isPin
                          ? "Pin this"
                          : "Add to saved"
            }
            sx={{
                backgroundColor: isSaved ? themeColors.primary3 : "transparent",
                borderColor: isSaved ? themeColors.primary3 : undefined,
                cursor: readOnly ? "default" : "pointer",
            }}>
            <SaveIcon
                aria-label={isFavorite ? "Favorite" : isPin ? "Pin" : "Save"}
                color={isSaved ? themeColors.pureBlack : themeColors.neutralBlack}
                fill={isSaved ? themeColors.pureBlack : themeColors.neutralBlack}
            />
        </UserContentBorderedBtn>
    );
};

export const PublicIcon = ({
    note,
    flashcardSet,
    assignment,
}: { note?: Note; flashcardSet?: FlashcardSet; assignment?: Assignment }) => {
    const typedItem = note || flashcardSet || assignment;
    const openContentItemsSharingPopup = useContentItemsSharingPopupContextSelector(
        state => state.openContentItemsSharingPopup
    );

    const isPublic = !!typedItem?.public;
    const hasPassword = !!typedItem?.password;

    return (
        <UserContentBorderedBtn
            onClick={e => {
                e.stopPropagation();

                if (!typedItem) return;

                openContentItemsSharingPopup({
                    notes: note ? [note] : undefined,
                    flashcardSets: flashcardSet ? [flashcardSet] : undefined,
                    assignments: assignment ? [assignment] : undefined,
                    itemType: note
                        ? ItemType.NOTE
                        : flashcardSet
                          ? ItemType.FLASHCARDSET
                          : assignment
                            ? ItemType.ASSIGNMENT
                            : undefined,
                });
            }}
            radius="2.6rem"
            aria-label={"share"}>
            {isPublic ? (
                <Globe size={iconSizes.SM_S} color={themeColors.neutralBlack} />
            ) : hasPassword ? (
                <KeyRound size={iconSizes.SM_S} color={themeColors.neutralBlack} />
            ) : (
                <Lock size={iconSizes.SM_S} color={themeColors.neutralBlack} />
            )}
        </UserContentBorderedBtn>
    );
};

export const CardRating = ({ rating, ratingCount }: { rating?: number | null; ratingCount?: number | null }) => {
    return (
        <FlexRowAlignCenter style={{ gap: "0.5rem", fontSize: "1.1rem", overflow: "hidden" }}>
            <span className="bold" style={{ width: "fit-content", height: "1.2rem" }}>
                {rating?.toFixed(1) || "0.0"}
            </span>
            <StarRating readOnly max={1} value={rating || 0} sx={{ fontSize: "1.4rem" }} />
            <span style={{ color: themeColors.neutral3 }}>({ratingCount || 0})</span>
        </FlexRowAlignCenter>
    );
};

export const SelectableButton = ({ isSelected, onClick }: { isSelected: boolean; onClick?: () => void }) => {
    return (
        <UserContentBorderedBtn
            tooltip={"select"}
            onClick={e => {
                e.stopPropagation();
                onClick?.();
            }}
            sx={{
                borderRadius: "0.5rem",
                border: !isSelected ? "2px solid" : "0",
                borderColor: !isSelected ? themeColors.neutral1 : themeColors.neutralBlack,
                backgroundColor: !isSelected ? "transparent" : themeColors.neutralBlack,
                marginRight: "1.6rem",
                "& svg": { strokeWidth: 3.5, width: "1.4rem", height: "1.4rem" },
            }}>
            <Check color={themeColors.neutralWhite} />
        </UserContentBorderedBtn>
    );
};

export const OwnerDetails = ({ ownerDetails }: { ownerDetails?: Pick<UserDetails, "username" | "pictureUrl"> }) => {
    return (
        <UserAvatar
            title={ownerDetails?.username || ""}
            alt={ownerDetails?.username}
            radius="2.4rem"
            src={ownerDetails?.pictureUrl ?? undefined}
            sx={{ border: `1px solid ${themeColors.neutral1}`, "& > svg": { strokeWidth: 2.5 } }}
        />
    );
};

export const ContentPill = ({
    children,
    style,
    ...spanProps
}: {
    children: React.ReactNode;
    style?: React.CSSProperties;
} & React.HTMLAttributes<HTMLSpanElement>) => {
    return (
        <FlexColumnAlignJustifyCenter
            as={"span"}
            className="secondaryTextBold1"
            style={{
                borderRadius: 10,
                maxWidth: "100%",
                width: "fit-content",
                height: "3.4rem",
                padding: "0.8rem 1.2rem",
                fontSize: "1.2rem",
                flexShrink: 0,
                backgroundColor: "var(--color-current-pill)",
                transition: "background-color 0.14s",
                ...style,
            }}
            {...spanProps}>
            {children}
        </FlexColumnAlignJustifyCenter>
    );
};

export const clickOptionMenuBtn = ({ id }: { id: string }) => document.getElementById(id + "options-menu")?.click();

export const ThreeDotsBtn = ({
    onClick,
    sx,
    color,
    itemId,
}: CircularButtonProps & { color?: string; itemId: string }) => {
    return (
        <UserContentBorderedBtn
            id={itemId + "options-menu"}
            onClick={e => {
                preventStopPropogation(e);
                onClick?.(e);
            }}
            tooltip="More actions"
            sx={{ "& > svg": { color, width: "1.4rem", height: "1.4rem" }, zIndex: 100, ...sx }}>
            <EllipsisVertical fill={themeColors.neutralBlack} strokeWidth={strokeWidth.bold} />
        </UserContentBorderedBtn>
    );
};

export const CarouselArrowBtn = ({ isNext, onClick }: { isNext: boolean; onClick: () => void }) => {
    return (
        <CircularOutlineIcon
            Icon={!isNext ? ChevronLeft : ChevronRight}
            size={iconSizes.SM}
            color={themeColors.neutralBlack}
            borderColor={themeColors.neutral1}
            buttonColor={themeColors.neutralWhite}
            hoverColor={themeColors.neutralWhite}
            hoverBackgroundColor={themeColors.neutralBlack}
            onClick={onClick}
            tooltip={`Go to linked resources`}
            // TODO: fix hoverBackgroundColor and hoverColor props usage in the component @Akbar
            sx={{
                alignSelf: "center",
                "&:hover": { backgroundColor: themeColors.neutralBlack, color: themeColors.neutralWhite },
            }}
            radius="2.8rem"
        />
    );
};

export const CarouselDots = ({
    length,
    currIdx,
    onClick,
}: {
    length: number;
    onClick: (clickedIdx: number) => void;
    currIdx: number;
}) => {
    return (
        <FlexRowAlignCenter
            onClick={preventStopPropogation}
            style={{
                gap: "0.7rem",
                position: "absolute",
                bottom: "-1.3rem",
                left: "50%",
                transform: "translateX(-50%)",
                cursor: "default",
            }}>
            {new Array(length).fill(0).map((_, i) => {
                return (
                    <ConditionalTooltip key={i} tooltip={currIdx === i ? undefined : "Jump between linked files"}>
                        <Circle
                            size={8}
                            color={currIdx === i ? themeColors.neutralBlack : "#bfbfbf"}
                            fill={currIdx === i ? "#000000" : "#bfbfbf"}
                            style={{ cursor: currIdx === i ? "default" : "pointer" }}
                            onClick={() => onClick(i)}
                        />
                    </ConditionalTooltip>
                );
            })}
        </FlexRowAlignCenter>
    );
};
